import React from "react";
import type { ZudokuConfig } from "zudoku";
import ComingSoon from "./src/coming-soon";

const config: ZudokuConfig = {
  page: {
    pageTitle: "",
    logo: {
      src: {
        light: "/logos/logo-text.svg",
        dark: "/logos/logo-text.svg",
      },
      width: "200px",
    },
    banner: {
      message: `AtomicTax API is currently in beta. Please report any issues to https://help.atomictax.com`,
      color: "#324398",
      dismissible: true,
    },
  },
  theme: {
    light: {
      primary: "243 52% 39%",
      primaryForeground: "243 5.2% 96.95%",
    },
    dark: {
      primary: "243 52% 39%",
      primaryForeground: "243 5.2% 96.95%",
    },
  },
  topNavigation: [
    { id: "docs", label: "Documentation" },
    { id: "coming-soon", label: "API Reference" },
    { id: "https://help.atomictax.com", label: "Support" },
    { id: "https://app.atomictax.com/login", label: "Login" },
    { id: "https://app.atomictax.com/register", label: "Register" },
  ],
  sidebar: {
    docs: [
      {
        type: "category",
        label: "Overview",
        items: ["introduction", "authentication", "rate-limiting"],
      },
    ],
    integration: ["introduction", "shopify", "stripe"],
  },
  redirects: [
    { from: "/", to: "/docs" },
    { from: "/docs", to: "/docs/introduction" },
  ],
  apis: [
    {
      type: "file",
      input: `./apis/openapi1.yaml`,
      navigationId: "api",
    },
  ],
  customPages: [
    {
      path: "/coming-soon",
      element: React.createElement(ComingSoon),
    },
  ],
  docs: {
    files: "/pages/**/*.{md,mdx}",
  },
  metadata: {
    title: "AtomicTax API Documentation",
    description: "AtomicTax helps online businesses stay compliant with sales tax laws effortlessly. Our API allows for seamless integration to automate sales tax registration, collection, calculation, filing, and multi-state nexus monitoring. Below, you’ll find key details to get started.",
    logo: "/logos/logo-text.svg",
    favicon: "/favicon.ico",
    applicationName: "AtomicTax Sales Tax Automation",
    referrer: "no-referrer",
    keywords: ["sales tax software", "sales tax filing", "shopify sales tax", "sales tax permit", "ecommerce sales tax"],
  },
};

export default config;
