import { useState } from "react";
import { Send } from "zudoku/icons";

const ComingSoon = () => {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("idle");
  const [message, setMessage] = useState("");

  const ZAPIER_WEBHOOK_URL = "https://hooks.zapier.com/hooks/catch/13482652/299e5rc/";

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setMessage("Please enter an email address");
      setStatus("error");
      return;
    }

    try {
      setStatus("loading");

      const response = await fetch(ZAPIER_WEBHOOK_URL, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({ email }),
      });

      if (!response.ok) throw new Error("Submission failed");

      setStatus("success");
      setMessage("Thanks! We'll notify you when we launch.");
      setEmail("");
    } catch (error) {
      setStatus("error");
      setMessage("Something went wrong. Please try again at a later time.");
    }
  };

  return (
    <div className="flex items-center justify-center h-[calc(100vh-168px)]">
      <div className="max-w-2xl w-full text-center mx-auto">
        <h1 className="text-6xl font-bold mb-8 bg-gradient-to-r from-green-400 via-blue-500 to-pink-500 text-transparent bg-clip-text">Coming Soon</h1>
        <p className="text-xl text-gray-500 mb-4 max-w-xl mx-auto leading-relaxed font-bold">Exciting News! Documentation is on the Way.</p>
        <p className="text-xl text-gray-500 mb-12 max-w-xl mx-auto ">We're building a comprehensive guide to make using AtomicTax seamless and efficient. Soon, you'll find everything you need.</p>

        <form onSubmit={handleSubmit} className="max-w-md mx-auto mb-16">
          <div className="flex flex-col sm:flex-row gap-3">
            <input type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} disabled={status === "loading"} className="flex-1 px-4 py-3 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-200" required />
            <button type="submit" disabled={status === "loading"} className="px-6 py-3 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors flex items-center justify-center gap-2 sm:w-auto w-full font-medium">
              {status === "loading" ? "Sending..." : "Notify Me"}
              <Send size={16} />
            </button>
          </div>

          {message && <div className={`text-center text-sm ${status === "success" ? "text-green-400" : "text-red-400"}`}>{message}</div>}

          <p className="text-gray-400 mt-4">_- Notify me when App is launched -_</p>
        </form>
      </div>
    </div>
  );
};

export default ComingSoon;
